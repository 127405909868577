<template>
  <div class="group animate-dropdown block">
    <Link
      :class="[
        'flex items-center gap-[5px] text-sm 2xl:gap-[10px]',
        checkActiveState
          ? 'text-white'
          : 'text-[rgba(255,255,255,0.5)] group-hover:text-white',
      ]"
      :href="useRoute(menu.route, { event: pageHaveEvent?.id })"
      :data-tutorial-selector="menu.selector || null"
    >
      <FontAwesomeIcon
        v-if="menu.icon"
        :icon="menu.icon"
        class="mb-[2px] py-[25px] text-xs 2xl:py-0 2xl:text-sm text-[#9C63FF]"
      />
      <span
        :class="[
          'mr-[2px] hidden whitespace-nowrap border-b-2 py-[8px] text-xs xl:inline-block 2xl:text-sm',
          checkActiveState
            ? 'border-[#9c63ff]'
            : 'border-transparent group-hover:border-[#9c63ff]',
        ]"
        v-text="$t(`common.${menu.label}`)"
      />
      <FontAwesomeIcon :icon="['fas', 'angle-down']" class="mb-[3px] text-sm" />
    </Link>

    <div class="dropdown-div absolute pt-[7px] 2xl:pt-5">
      <div
        class="w-[180px] text-[#7b8190] shadow-[0_5px_6px_rgb(52,58,64,0.10)] bg-white p-[12px_12px_4px_12px]"
      >
        <h4
          class="mb-[8px] text-[11px] font-bold uppercase tracking-[1px] text-[#3f1e59] opacity-[0.4]"
          v-text="title"
        />

        <template v-for="child in activeMenuChildren" :key="child.label">
          <div
            class="group/item relative last:bg-black"
            :class="menu.childSubmenu && 'with-submenu'"
          >
            <Link
              class="flex items-center gap-x-1 py-[10px] px-[6px] mx-[-6px] group-hover/item:bg-[#F8F4FF] group-hover/item:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute(child.route, { event: event?.id })"
            >
              <p
                class="text-xs font-bold flex-1"
                v-text="$t(`common.${child.label}`)"
              />

              <!-- TODO: Uncomment for enable SMS/Upgraded condition -->
              <!-- <span
                v-if="child.check_sms_enabled"
                class="block w-[50px] rounded-[2px] bg-[#F4F4F4] h-3 px-1 text-center text-[#7A7A7A78] text-[8px] font-medium tracking-normal mr-1"
              >{{ $t('common.upgrade') }}</span> -->

              <!-- TODO: Hide if sms needs upgrade / also add condition -->
              <FontAwesomeIcon
                v-if="menu.childSubmenu"
                :icon="['fas', 'angle-right']"
                class="mb-[3px] text-sm"
              />
            </Link>

            <div
              v-if="menu.childSubmenu"
              class="child-submenu-div absolute -top-[7px] right-[-194px] pl-3"
            >
              <div
                class="w-[180px] text-[#7b8190] shadow-[2px_2px_4px_0px_#00000040] bg-white px-3 pt-2 pb-1"
              >
                <template
                  v-for="childSubmenu in child.submenu.filter((submenu) => {
                    return (
                      usePermission(submenu.permission) &&
                      (!submenu.limit || hasSubscription(submenu.limit))
                    )
                  })"
                  :key="childSubmenu.label"
                >
                  <Link
                    class="with-submenu block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
                    :href="useRoute(childSubmenu.route, { event: event?.id })"
                  >
                    <p
                      class="text-xs font-bold"
                      v-text="$t(`common.${childSubmenu.label}`)"
                    />
                  </Link>

                  <div
                    class="border-b border-dashed border-[#dbdbdb] my-2"
                  ></div>
                </template>
              </div>
            </div>
          </div>

          <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePermission } from '@/composables/permission'
import { useRoute } from '@/composables/route'
import { hasSubscription } from '@/composables/subscription'
import { Link, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
  menu: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
})

const event = computed(() => usePage().props.event)
const pageHaveEvent = computed(() => usePage().props.event)
const checkActiveState = computed(() => {
  if (usePage().component === props.menu.name) return true

  if (props.menu.child.length) {
    return props.menu.child.some(({ name, submenu }) => {
      if (usePage().component === name) return true

      if (submenu && submenu.length) {
        return submenu.some(({ name }) => usePage().component === name)
      }
    })
  }

  return false
})
const activeMenuChildren = computed(() =>
  props.menu.child.filter((child) => {
    return (
      usePermission(child.permission) &&
      (!child.limit || hasSubscription(child.limit))
    )
  }),
)
</script>

<style>
.child-submenu-div {
  visibility: hidden;
  opacity: 0;
}
.with-submenu.show > .child-submenu-div {
  visibility: visible;
  opacity: 100;
  transition: all 0.3s;
  z-index: 999;
}

@media screen and (min-width: 768px) {
  .with-submenu:hover .child-submenu-div {
    visibility: visible;
    opacity: 100;
    transition: all 0.3s;
    z-index: 999;
  }
}
</style>
